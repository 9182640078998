@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: #eef9f5;
}

.main-bg {
  width: 100vw;
  height: 100vh;
}

@media (min-width: 1024px) {
  .main-bg {
    background-image: url("/public/assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.active {
  @apply text-green;
}
